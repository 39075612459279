import { useState, useEffect, useRef } from "react";
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Checkbox } from "@/components/ui/checkbox";
import { Send, Mic, XIcon } from "lucide-react";
import { useReadItems } from "@/lib/compensations/read";
import { useReadActiveUser } from "@/lib/user/read";
import { consultAI } from "@/lib/ai/consult-ai";
import { useUpdateActiveUser } from "@/lib/user/update";
import { useNavigate } from "react-router-dom";

const AIChatPopup = () => {
  const redirect = useNavigate();
  const { handleUpdateSubmit } = useUpdateActiveUser();
  const { prunedData: actionable_tickets } = useReadItems();
  const { issues } = useReadActiveUser();
  const [error, setError] = useState<string | null>(null);
  const [temporaryDisabled, setTemporaryDisabled] = useState(false);
  const [isThinking, setIsThinking] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState<{ text: string; sender: string }[]>(
    []
  );
  const [input, setInput] = useState("");
  const [context, setContext] = useState({
    actionable_tickets: actionable_tickets || [],
    issues: issues || [],
  });

  const contentRef = useRef(null);

  const handleSend = () => {
    if (input.trim()) {
      const newMessage = { text: input, sender: "user" };
      setMessages((prev) => [...prev, newMessage]);
      handleSendMessage({ message: input });
      setInput("");
    }
  };

  useEffect(() => {
    if (contentRef.current) {
      // @ts-expect-error - scrollHeight is a valid property
      contentRef.current.scrollTop = contentRef.current.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    if (
      actionable_tickets &&
      issues &&
      context.actionable_tickets?.length !== actionable_tickets.length &&
      context.issues?.length !== issues.length
    ) {
      setContext({ actionable_tickets: [], issues: issues || [] });
    } else
      setContext({
        actionable_tickets: actionable_tickets || [],
        issues: issues || [],
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionable_tickets?.length, issues?.length]);

  const handleSendMessage = async ({ message }: { message: string }) => {
    try {
      setIsThinking(true);
      console.log({ context });
      const form = new FormData();
      form.append("message", message);
      form.append("context", JSON.stringify(context));
      const output = await consultAI(form);
      setIsThinking(false);
      if (output) {
        const structuredResponse = JSON.parse(output);
        console.log({ structuredResponse });
        if (structuredResponse?.action?.includes("update_user_settings")) {
          const updateAction = handleUpdateSubmit(
            Object.keys(structuredResponse.answer)
          );
          const update = JSON.parse(
            structuredResponse.action
              .replace("update_user_settings(", "")
              .replace(")", "")
          );
          console.log("Updating user settings", { update });
          await updateAction(null, update);
          await wait(1000);
          redirect("/settings/profile?goTo=" + Object.keys(update)[0]);
        }
        const newMessage = { text: structuredResponse.answer, sender: "robot" };
        setMessages((prev) => [...prev, newMessage]);
      }
    } catch (error) {
      console.error("Error calling OpenAI:", error);
      setIsThinking(false);
      setError("Error calling OpenAI");
    }
  };

  if (temporaryDisabled) {
    return null;
  }

  console.log({ actionable_tickets, issues });

  return (
    <AlertDialog open={isOpen} onOpenChange={setIsOpen}>
      <AlertDialogTrigger asChild>
        <Button className="fixed bottom-16 right-4 z-50">Chat (beta)</Button>
      </AlertDialogTrigger>
      <AlertDialogContent className="max-w-md">
        <div className="flex flex-col h-[80vh]">
          <div
            className="flex-grow overflow-y-auto p-4 space-y-4"
            ref={contentRef}
          >
            {messages.map((msg, index) => (
              <div
                key={index}
                className={`flex ${
                  msg.sender === "user" ? "justify-end" : "justify-start"
                }`}
              >
                <div
                  className={`max-w-[70%] p-2 rounded-lg ${
                    msg.sender === "user"
                      ? "bg-primary text-black font-bold"
                      : "bg-blue-800 text-white"
                  }`}
                >
                  {msg.text || "..."}
                </div>
              </div>
            ))}
            {isThinking && (
              <div className="flex justify-center">
                <div className="animate-spin h-4 w-4 border-t-2 border-b-2 border-blue-500 rounded-full"></div>
              </div>
            )}
            {error && (
              <div className="bg-red-500 text-white p-2 rounded-lg">
                {error}
              </div>
            )}
          </div>
          <div className="p-4 border-t">
            <div className="flex space-x-2">
              <Input
                value={input}
                onChange={(e) => setInput(e.target.value)}
                placeholder="Wpisz wiadomość..."
                onKeyPress={(e) => e.key === "Enter" && handleSend()}
                autoFocus
              />
              <Button disabled variant={"outline"}>
                <Mic className="h-4 w-4" />
              </Button>
              <Button onClick={handleSend}>
                <Send className="h-4 w-4" />
              </Button>
            </div>
            <div className="flex items-center space-x-2 mt-2">
              <Checkbox
                id="temporaryDisabled"
                checked={temporaryDisabled}
                onCheckedChange={() => setTemporaryDisabled(true)}
              />
              <label
                htmlFor="temporaryDisabled"
                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              >
                Wyłącz chat póki nie odświeżę strony
              </label>
              <Button onClick={() => setIsOpen(false)}>
                <XIcon className="h-4 w-4" />
              </Button>
            </div>
          </div>
        </div>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default AIChatPopup;
const wait = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
