import { provinces } from "@/utils/provinces.pl";
import { Database } from "../supabase/schema";
import { GenericInputField } from "../types";

export const fields: Record<
  keyof Database["public"]["Tables"]["profiles"]["Row"],
  GenericInputField
> = {
  address_line_1: {
    label: "Adres linia 1",
    input: "text",
    placeholder: "np nazwa ulicy i numer domu",
    validator: (value) =>
      value?.length > 2 ? new RegExp(/^[\p{L}\p{N}\s]+$/u).test(value) : true,
    validationMessage: "Co najmniej 3 znaki bez znaków specjalnych",
  },
  address_line_2: {
    label: "Adres linia 2",
    input: "text",
    placeholder: "np numer mieszkania",
    validator: (value) =>
      value?.length ? new RegExp(/^[\p{L}\p{N}\s]+$/u).test(value) : true,
    validationMessage: "Bez znaków specjalnych",
  },
  allow_app_push_notifications: {
    label: "Zezwól na powiadomienia push",
    input: "checkbox",
  },
  allow_marketing_emails: {
    label: "Zezwól na maile marketingowe",
    input: "checkbox",
  },
  allow_marketing_push_notifications: {
    label: "Zezwól na powiadomienia push marketingowe",
    input: "checkbox",
  },
  allow_marketing_text_messages: {
    label: "Zezwól na SMS marketingowe",
    input: "checkbox",
  },
  app_language_code: {
    label: "Kod języka aplikacji",
    input: "text",
  },
  city: {
    label: "Miasto",
    input: "text",
    placeholder: "np Kraków",
    validator: (value) =>
      value?.length > 2 && new RegExp(/^[\p{L}\p{N}\s]+$/u).test(value),
    validationMessage: "Co najmniej 3 znaki bez cyfr i znaków specjalnych",
  },
  country: {
    label: "Kraj",
    input: "text",
    placeholder: "Polska",
  },
  created_at: {
    label: "Data utworzenia",
    input: "date",
  },
  delay_archiving_in_days: {
    label: "Opóźnienie archiwizacji w dniach",
    input: "number",
    readonly: true,
  },
  email: {
    label: "Email",
    input: "email",
    validator: (value) =>
      value?.length > 5 && value.includes("@") && value.includes("."),
    validationMessage: "Sprawdź poprawność adresu email",
  },
  flagged: {
    label: "Oflagowany",
    input: "checked",
    readonly: true,
  },
  full_name: {
    label: "Pełne imię i nazwisko",
    input: "text",
    placeholder: "np Jan Kowalski",
    validator: (value) =>
      value?.length > 5 && new RegExp(/^[\p{L}\s]+$/u).test(value),
    validationMessage: "Co najmniej 6 znaków bez cyfr i znaków specjalnych",
  },
  id: {
    label: "Id",
    input: "number",
    readonly: true,
  },
  phone: {
    label: "Telefon",
    input: "phone",
    placeholder: "np +48 123 456 789",
    validator: (value) =>
      new RegExp(/^\+[0-9]{2} [0-9]{3} [0-9]{3} [0-9]{3}$/).test(value) ||
      new RegExp(/^\+[0-9]{2}[0-9]{9}$/).test(value),
    validationMessage: "Musi być w formacie +48 123 456 789 lub +48123456789",
  },
  province: {
    label: "Województwo",
    input: "text",
    placeholder: "np małopolskie",
    validator: (value) =>
      provinces.map((p) => p.toLocaleLowerCase()).includes(
        value?.trim().toLocaleLowerCase(),
      ),
    validationMessage: "Podaj poprawne województwo",
  },
  updated_at: {
    label: "Data aktualizacji",
    input: "date",
    readonly: true,
  },
  username: {
    label: "Nazwa użytkownika",
    input: "text",
  },
  zip: {
    label: "Kod pocztowy",
    input: "text",
    placeholder: "np 30-001",
    validator: (value) => new RegExp(/^[0-9]{2}-[0-9]{3}$/).test(value.trim()),
    validationMessage: "Musi być w formacie 00-000",
  },
  iban: {
    label: "IBAN",
    input: "text",
    placeholder: "nr konta bankowego dla przelewów",
    validator: (value) =>
      new RegExp(/^[A-Z]{2}\d{2} ?\d{4} ?\d{4} ?\d{4} ?\d{4} ?\d{4} ?\d{4}$/)
        .test(
          value,
        ),
    validationMessage: "Musi być w formacie PL00 0000 0000 0000 0000 0000 0000",
  },
  offences_allowed: {
    label: "Limit przewinień",
    input: "number",
    readonly: true,
  },
  offences_committed: {
    label: "Popelnione przewinienia",
    input: "number",
    readonly: true,
  },
  crud_rights: {
    label: "CRUD prawa użytkownika",
    input: "text",
    readonly: true,
  },
};

export const userReadableProfileFieldsString = Object.keys(fields).join(
  ", ",
) as keyof Database["public"]["Tables"]["profiles"]["Row"];
