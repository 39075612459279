import { supabase } from "../supabase/supabase";

export async function consultAI(formData: FormData): Promise<string> {
  let output = "";

  try {
    const { data, error } = await supabase.functions.invoke(
      "consult-ai",
      {
        body: formData,
      },
    );
    if (error) {
      console.error(error);
      output = "error";
    }
    if (data) {
      output = data;
    }
  } catch (e) {
    console.error(e);
    output = "error";
  }

  return output;
}
