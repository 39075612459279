import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { SWRConfig } from "swr";
import * as Sentry from "@sentry/react";
import { PostHogProvider } from "posthog-js/react";
import { router } from "./components/routing/page-router";
import { ErrorPage } from "./components/layout/error-page";
import AuthProvider from "./lib/auth/auth-provider";
import { ThemeProvider } from "./lib/theme/theme-provider";
import "./index.css";

const options = {
  api_host: import.meta.env.VITE_APP_PUBLIC_POSTHOG_HOST,
};

console.log("options", options);

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <PostHogProvider
      apiKey={import.meta.env.VITE_APP_PUBLIC_POSTHOG_KEY}
      options={options}
    >
      <ThemeProvider defaultTheme="dark" storageKey="pay4delay-ui-theme">
        <Sentry.ErrorBoundary fallback={ErrorPage}>
          <AuthProvider>
            <SWRConfig
              value={{
                revalidateIfStale: false,
                revalidateOnFocus: false,
              }}
            >
              <RouterProvider router={router} />
            </SWRConfig>
          </AuthProvider>
        </Sentry.ErrorBoundary>
      </ThemeProvider>
    </PostHogProvider>
  </React.StrictMode>
);
