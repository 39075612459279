import { supabase } from "../supabase/supabase";
import { ReadItem } from "../compensations/types";

export async function readTicket(formData: FormData): Promise<ReadItem> {
  let output = {} as ReadItem;

  try {
    const { data, error } = await supabase.functions.invoke(
      "create-compensation",
      {
        body: formData,
      },
    );
    if (error) {
      console.error(error);
      output.is_ticket = false;
    }
    if (data) {
      output = data;
    }
  } catch (e) {
    console.error(e);
  }

  return output;
}
