import { useToast } from "@/components/ui/use-toast";
import { ActiveUser } from "./types";
import { useUpdateUserProfile } from "../supabase/crud-helpers/user";
import { logger } from "../logger";

export const useUpdateActiveUser = () => {
  const { toast } = useToast();

  const { updateProfile } = useUpdateUserProfile();

  const handleUpdateSubmit =
    (fields: string[]) =>
    (e?: React.FormEvent<HTMLFormElement> | null, directUpdate?: object) => {
      e?.preventDefault?.();
      const formData = new FormData(e?.currentTarget);
      const update = directUpdate ||
        Object.values(fields).reduce((acc, field) => {
          let f = formData.get(field);

          // f = f === "" ? null : f;
          // @ts-expect-error - true is a valid value for a boolean field
          f = f === "on" ? true : f;
          // @ts-expect-error - field can be null
          f = f === null ? false : f;

          // if (f) {
          // @ts-expect-error - fix later, f is a key of ActiveUser["data"]
          acc[field] = typeof f === "string" ? f.trim() : f;
          // }
          return acc;
        }, {});

      console.log({ update });

      if (Object.keys(update || {}).length === 0) return;
      updateProfile(update as Omit<ActiveUser["profile"], "id">)
        .then(() => {
          toast({
            title: "Success",
            description: "Zaktualizowano dane osobowe.",
          });
          logger.metrics("update_profile");
        })
        .catch(() => {
          logger.error("Failed to update user profile", { update });
          toast({
            title: "Błąd",
            description: "Nie udało się zaktualizować danych osobowych.",
          });
        });
    };

  return { handleUpdateSubmit };
};
